import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class DuracionTratamientoService {
  listarDuracionTratamiento () {
    return http.get(`${baseUrl}/duracion-tratamiento/listar`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/duracion-tratamiento/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getDur (id) {
    return http.get(`${baseUrl}/duracion-tratamiento/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearDur (data) {
    return http.post(`${baseUrl}/duracion-tratamiento`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarDur (data) {
    return http.put(`${baseUrl}/duracion-tratamiento/${data.Id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarDur (id) {
    return http.delete(`${baseUrl}/duracion-tratamiento/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
